/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, FUNCTIONS){
	const a = 'is-active';
	const v = 'is-visible';

	const test = () => {
		// console.log('test');
	}

	test();

})(window.jQuery, window.FUNCTIONS);
